import XIcon from "@lux/icons/X16.svg";
import React, { DOMAttributes } from "react";

import { LuxButton } from "./LuxButton";

export const CloseButton = ({
  variant = "solid",
  rounded = true,
  onClick,
  className,
}: {
  variant?: "solid" | "naked";
  rounded?: boolean;
  onClick: DOMAttributes<HTMLElement>["onClick"];
  className?: string;
}) => {
  return (
    <LuxButton
      className={className}
      label="Close"
      icon={<XIcon />}
      iconPlacement="icon-only-compact"
      onClick={onClick}
      color="light"
      variant={variant}
      rounded={rounded}
      size="small"
    />
  );
};
