import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom";

import { useIsMounted } from "../hooks/useIsMounted";
import { useWindowSize } from "../hooks/useWindowSize";
import { FRAMER_BOUNCE_TRANSITION, FRAMER_TRANSITION } from "../utils/framer";
import { ResponsiveBreakpointNumbers } from "../utils/StyleConstants";
import { CloseButton } from "./CloseButton";
import { LuxBackdrop } from "./LuxBackdrop";
import { LuxOverlay } from "./LuxOverlay";
import { useRegisterModal } from "./ModalContext";

type ModalVariant = "regular" | "compact" | "lux-alert";

/**
 * we only want to render the modal when it is shown
 * if we render the modal when it is hidden, we run into two problems
 *
 * 1. re-opening the modal may not trigger a re-render so the modal will have old info
 * 2. when hidden, we may not have valid data to render the modal
 *
 * Most of the time you will just pass in children like:
 *
 * ```
 * <LuxModalContainer>
 *   {show && (...)}
 * </LuxModalContainer>
 * ```
 */
export const LuxModalContainer = ({
  title,
  variant = "regular",
  onHide,
  canClickOutToDismiss = true,
  children,
}: {
  title?: React.ReactNode;
  variant?: ModalVariant;
  onHide: () => void;
  canClickOutToDismiss?: boolean;
  children: React.ReactNode | null;
}) => {
  const shouldShow = Boolean(children);
  useRegisterModal({
    shown: shouldShow,
    fullScreenMode: variant === "lux-alert" ? "never" : "mobile-only",
  });
  const mounted = useIsMounted();

  if (!mounted) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <AnimatePresence>{shouldShow && <LuxBackdrop />}</AnimatePresence>,
        document.body
      )}

      {ReactDOM.createPortal(
        <AnimatePresence>
          {shouldShow && (
            <LuxOverlay
              onHide={onHide}
              canClickOutToDismiss={canClickOutToDismiss}
            >
              <LuxModal title={title} onHide={onHide} variant={variant}>
                {children}
              </LuxModal>
            </LuxOverlay>
          )}
        </AnimatePresence>,
        document.body
      )}
    </>
  );
};

const LuxModalHeader = ({
  title,
  onHide,
}: {
  title: React.ReactNode;
  onHide: () => void;
}) => {
  return (
    <div className="lux-modal-header flex-center spread">
      <div className="title">{title}</div>
      <CloseButton onClick={onHide} />
    </div>
  );
};

export const MODAL_ANIMATION = {
  hidden: { scale: 0.9, opacity: 0 },
  shown: { scale: 1, opacity: 1 },
};

const LuxModal = ({
  title,
  children,
  className,
  variant = "regular",
  onHide,
}: {
  title?: React.ReactNode;
  className?: string;
  variant?: ModalVariant;
  onHide: () => void;
  children: React.ReactNode | null;
}) => {
  const windowSize = useWindowSize();
  const isMobile = (windowSize.width ?? 0) <= ResponsiveBreakpointNumbers.tiny;

  return (
    <motion.div
      key="modal"
      className={classNames("lux-modal", className, variant)}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      initial="hidden"
      animate="shown"
      exit="hidden"
      transition={
        isMobile && variant === "regular"
          ? FRAMER_TRANSITION
          : FRAMER_BOUNCE_TRANSITION
      }
      variants={MODAL_ANIMATION}
    >
      {title && <LuxModalHeader title={title} onHide={onHide} />}

      <div className="lux-modal-body overflow-auto">{children}</div>
    </motion.div>
  );
};
