import classNames from "classnames";
import { motion } from "framer-motion";
import React from "react";

import { FRAMER_TRANSITION } from "../utils/framer";

export const LuxBackdrop = ({ isGlass }: { isGlass?: boolean }) => {
  return (
    <motion.div
      key="backdrop"
      className={classNames("lux-backdrop", { glass: isGlass })}
      initial="hidden"
      animate="shown"
      exit="hidden"
      variants={{
        hidden: { opacity: 0 },
        shown: { opacity: 1 },
      }}
      transition={FRAMER_TRANSITION}
    />
  );
};
