import React from "react";
import { QRModal } from "./QRModal";

export const DownloadAppModal = () => {
  return (
    <QRModal
      title="Your favorite Solana wallet awaits"
      description="Scan the QR code above, or search for Glow Wallet in the App Store or Google Play."
      url="http://glow.app/dl"
    />
  );
};
