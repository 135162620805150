import classNames from "classnames";
import React, { CSSProperties } from "react";

import {
  getWalletImageUrl,
  WalletTheme,
  WalletThemeToInfo,
} from "./wallet-theme";

export const GlowWalletIcon = ({
  className,
  theme,
  size = 40,
  ...props
}: {
  className?: string;
  theme?: WalletTheme;
  size?: number;
} & (
  | { selectable?: false }
  // We need a "selectable" prop because the icon size shrinks by 10px if it is
  | { selectable: true; selected: boolean; onClick: () => void }
)) => {
  const { colorA, colorB } = theme
    ? WalletThemeToInfo[theme]
    : {
        colorA: "var(--quaternary-bg-color)",
        colorB: "var(--quaternary-bg-color)",
      };

  return (
    <div
      className={classNames("wallet-icon", className, {
        selectable: props.selectable,
        large: size > 60,
      })}
      onClick={props.selectable ? props.onClick : undefined}
      style={
        {
          "--color-a": colorA,
          "--color-b": colorB,
          "--size": `${size}px`,
        } as CSSProperties
      }
    >
      <div className="wallet-icon-bg-container">
        <div className="wallet-icon-bg" />
      </div>

      {props.selectable && props.selected && (
        <div className="wallet-icon-selected-border" />
      )}

      <picture className="wallet-icon-image">
        <source
          srcSet={getWalletImageUrl({
            size,
            theme: theme ?? "unknown",
            dark: true,
          })}
          media="(prefers-color-scheme: dark)"
        />
        <img
          src={getWalletImageUrl({
            size,
            theme: theme ?? "unknown",
            dark: false,
          })}
          style={{ width: size * 0.625 }}
        />
      </picture>

      <style jsx>{`
        .wallet-icon {
          --selected-border-margin: 0px;

          width: var(--size);
          height: var(--size);
          border-radius: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          &.selectable {
            --selected-border-margin: 5px;
            cursor: pointer;
          }
        }

        .wallet-icon-selected-border {
          border: 2px solid var(--color-a);
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
        }

        .wallet-icon-bg-container {
          overflow: hidden;
          position: absolute;
          top: var(--selected-border-margin);
          left: var(--selected-border-margin);
          right: var(--selected-border-margin);
          bottom: var(--selected-border-margin);
          z-index: 0;
          border-radius: 50%;
        }

        .wallet-icon-bg {
          --bg-size: calc(var(--size) - var(--selected-border-margin) * 2);

          background: linear-gradient(135deg, var(--color-a), var(--color-b));
          width: var(--bg-size);
          height: var(--bg-size);
          opacity: 0.2;
        }

        .wallet-icon-image {
          z-index: 1;
        }

        .wallet-icon:not(.large) .wallet-icon-image {
          transform: translateY(-5%);
        }
      `}</style>
    </div>
  );
};
