import { useOnMount } from "@lux/atoms/hooks/useOnMount";
import React, { useEffect, useRef } from "react";
import QRCodeStyling from "@solana/qr-code-styling";
import classNames from "classnames";

export const QRCode = ({
  value,
  size,
  color = "#b3b5b7",
  className,
}: {
  value: string;
  size: number;
  color?: string;
  className?: string;
}) => {
  const qrCode = useRef<QRCodeStyling>();
  const ref = useRef(null);

  useOnMount(() => {
    qrCode.current = new QRCodeStyling({
      width: size,
      height: size,
      data: value,
      type: "svg",
      // GlowCoin.svg + CSS for fill color:
      // --primary-color = --black and --white
      // %23 is # as per https://stackoverflow.com/a/61099329/1123156)
      // We can't use var(--primary-color) because qr-code-styling
      // embeds image as href in a picture element which has its own
      // "CSS variables context".
      // `width` and `height` are explicitly set on the svg because
      // otherwise rendering is inconsistent across browsers. See:
      // https://github.com/luma-team/luma/pull/10063
      image: `data:image/svg+xml;utf8,
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.51472 3.51472C1.34315 5.68629 0 8.68629 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C8.68629 0 5.68629 1.34315 3.51472 3.51472ZM3.51472 3.51472C8.20121 8.20121 8.20121 15.7988 3.51472 20.4853C8.20121 15.7988 15.7988 15.7988 20.4853 20.4853C15.7988 15.7988 15.7988 8.20121 20.4853 3.51472C15.7988 8.20121 8.20121 8.20121 3.51472 3.51472Z" class="coin"/>
        <style>
          .coin {
            fill: ${color.replace("#", "%23")};
          }
        </style>
      </svg>`,
      dotsOptions: {
        color,
        type: "extra-rounded",
      },
      cornersSquareOptions: {
        type: "dot",
      },
      imageOptions: {
        margin: 0.03 * size,
        hideBackgroundDots: true,
        imageSize: 0.4,
      },
      backgroundOptions: {
        color: "transparent",
      },
    });
  });

  useEffect(() => {
    if (ref.current) {
      qrCode.current?.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    qrCode.current?.update({
      data: value,
      width: size,
      height: size,
      imageOptions: {
        margin: 0.03 * size,
      },
    });
  }, [qrCode, value, size]);

  return (
    <>
      <div className={classNames("qr-code-container", className)} ref={ref} />
      <style jsx>{`
        .qr-code-container :global(svg) {
          width: ${size}px;
          height: ${size}px;
        }
      `}</style>
    </>
  );
};
