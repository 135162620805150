import { QRCode } from "@glow/react/components/QRCode";
import GlowLogoIcon from "@lux/icons/glow/GlowLogo.svg";
import React from "react";

export const QRModal = ({
  title,
  description,
  url,
  cta,
}: {
  title: string;
  description: string;
  url: string;
  cta?: React.ReactNode;
}) => {
  return (
    <div className="modal">
      <div className="logo flex-center-center">
        <GlowLogoIcon />
      </div>
      <div className="qr-wrapper">
        <QRCode value={url} size={150} color="#000000" />
      </div>
      <div>
        <div className="title">{title}</div>
        <div className="desc">{description}</div>
        {cta}
      </div>

      <style jsx>{`
        .modal {
          padding: 1rem;
        }

        .qr-wrapper {
          margin: auto;
          max-width: calc(150px + 1rem);
          margin-bottom: 1rem;
          border-radius: var(--border-radius);
          overflow: hidden;
          background: var(--white);
          padding: 0.5rem;

          img {
            width: 100%;
          }
        }

        .logo {
          :global(svg) {
            width: auto;
            height: 1.5rem;
            margin-bottom: 1rem;
          }
        }

        .title,
        .desc {
          text-align: center;
        }

        .title {
          font-size: var(--font-size-lg);
          font-weight: var(--font-weight-bold);
        }

        .desc {
          font-size: var(--font-size-sm);
          margin-top: 0.25rem;
        }
      `}</style>
    </div>
  );
};
