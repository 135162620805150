import { useIsMounted } from "@lux/atoms/hooks/useIsMounted";

export const BROWSER_INFO = {
  chrome: {
    href: "https://chrome.google.com/webstore/detail/glow-solana-wallet-beta/ojbcfhjmpigfobfclfflafhblgemeidi?hl=en&authuser=0",
    label: "Chrome",
    icon: "chrome.png",
  },
  brave: {
    href: "https://chrome.google.com/webstore/detail/glow-solana-wallet-beta/ojbcfhjmpigfobfclfflafhblgemeidi?hl=en&authuser=0",
    label: "Brave",
    icon: "brave.png",
  },
  edge: {
    href: "https://microsoftedge.microsoft.com/addons/detail/glow-solana-wallet/niihfokdlimbddhfmngnplgfcgpmlido",
    label: "Edge",
    icon: "edge.png",
  },
  firefox: {
    href: "https://addons.mozilla.org/en-US/firefox/addon/glow-solana-wallet/",
    label: "Firefox",
    icon: "firefox.png",
  },
};

export const DESKTOP_DOWNLOADS = [
  BROWSER_INFO.chrome,
  BROWSER_INFO.edge,
  BROWSER_INFO.firefox,
  BROWSER_INFO.brave,
];

export const useCurrentBrowser = ():
  | "chrome"
  | "edge"
  | "brave"
  | "firefox"
  | null => {
  const isMounted = useIsMounted();
  if (!isMounted) {
    return null;
  }

  if (window.navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
    return "firefox";
  }

  if (window.navigator.userAgent.toLowerCase().indexOf("edg") > -1) {
    return "edge";
  }

  if ("brave" in window.navigator) {
    return "brave";
  }

  if (window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
    return "chrome";
  }

  return null;
};
