import sample from "lodash/sample";
import sortBy from "lodash/sortBy";

export enum WalletTheme {
  Cranberry = "cranberry",
  Blue = "blue",
  Green = "green",
  Barney = "barney", // TODO: convert pink to barney in the iOS app
  Purple = "purple",
  Yellow = "yellow",
}

export const WalletThemes = [
  WalletTheme.Cranberry,
  WalletTheme.Blue,
  WalletTheme.Green,
  WalletTheme.Barney,
  WalletTheme.Purple,
  WalletTheme.Yellow,
];

export const getRandomWalletTheme = (): WalletTheme => sample(WalletThemes)!;
export const getNextWalletTheme = ({
  accounts,
}: {
  accounts: Array<{ theme: WalletTheme }>;
}): WalletTheme => {
  const themeCounts: Map<WalletTheme, number> = new Map(
    WalletThemes.map((theme) => [theme, 0])
  );
  for (const { theme } of accounts) {
    themeCounts.set(theme, themeCounts.get(theme)! + 1);
  }

  // Pick from among least used ones
  const leastUsedThemes = sortBy(
    Array.from(themeCounts.entries()),
    ([_theme, count]) => count
  )
    .map(([theme]) => theme)
    .slice(0, 3);

  // Don't pick the same as last one
  const possibleThemes = leastUsedThemes.filter(
    (theme) => theme !== accounts.at(-1)?.theme
  );

  return sample(possibleThemes)!;
};

export const assignWalletsThemes = <Account>({
  accounts,
}: {
  accounts: Array<Account>;
}): { accounts: Array<Account & { theme: WalletTheme }> } => {
  const accountsWithThemes: Array<Account & { theme: WalletTheme }> = [];
  for (const storedAccount of accounts) {
    accountsWithThemes.push({
      ...storedAccount,
      theme: getNextWalletTheme({ accounts: accountsWithThemes }),
    });
  }

  return {
    accounts: accountsWithThemes,
  };
};

const WALLET_IMAGE_ROOT_URL = "https://cdn.glow.app/wallet-image";

export const getWalletImageUrl = ({
  size,
  theme,
  dark,
}: {
  size: number;
  theme: WalletTheme | "unknown";
  dark: boolean;
}): string => {
  const sizeModifier = size > 60 ? "" : "-small";
  return `${WALLET_IMAGE_ROOT_URL}/${theme}${sizeModifier}${
    dark ? "-dark" : "-light"
  }.png`;
};

export const WalletThemeToInfo: Record<
  WalletTheme,
  { colorA: string; colorB: string }
> = {
  [WalletTheme.Blue]: {
    colorA: "#8BE2FF",
    colorB: "#4A90F6",
  },
  [WalletTheme.Cranberry]: {
    colorA: "#FF96AF",
    colorB: "#FF337D",
  },
  [WalletTheme.Green]: {
    colorA: "#97FFA0",
    colorB: "#61DF6B",
  },
  [WalletTheme.Barney]: {
    colorA: "#FF90FF",
    colorB: "#FD68FF",
  },
  [WalletTheme.Purple]: {
    colorA: "#B595FF",
    colorB: "#682EFF",
  },
  [WalletTheme.Yellow]: {
    colorA: "#FFE85F",
    colorB: "#FFA800",
  },
};
